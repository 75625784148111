$body-size: 20px;
$navbar-item-img-max-height: 3rem;
$red: hsl(358, 81, 56);
$primary: $red;
$link: $red;
$control-radius:290486px;
@import "../../node_modules/bulma/bulma";
@import "../../node_modules/bulma-extensions/bulma-carousel/dist/css/bulma-carousel.sass";
.hero{
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &::after{
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: $dark;
    opacity: 0.8;
  }
  &:hover{
    &::after{
      background-color: $red;
    }
  }
}
.hero-is-active{
  &::after{
    background-color: $red !important;
  }
}
.hero-body{
  z-index: 10;
}
.hero-homepage{
  background-image: url('/img/homepage-bg.jpg') !important;
  video{
    position: absolute;
    top:50%;
    left:50%;
    min-width: 100%;
    min-height: 100%;
    width:auto;
    height:auto;
    transform: translateX(-50%) translateY(-50%);
  }

}
.hero-live-production{
  background-image: url('/img/services-liveproduction.jpg') !important;
}
.hero-staging-covers{
  background-image: url('/img/services-live-production-staging-and-covers.jpg') !important;
}
.hero-live-audio{
  background-image: url('/img/services-live-production-live-audio.jpg') !important;
}
.hero-show-lighting{
  background-image: url('/img/services-live-production-show-lighting.jpg') !important;
}
.hero-video-technology{
  background-image: url('/img/services-live-production-video-technology.jpg') !important;
}
.hero-set-designs-backdrops{
  background-image: url('/img/services-live-production-set-designs-backdrops.jpg') !important;
}
.hero-installations{
  background-image: url('/img/services-installations.jpg') !important;
}
.hero-rentals{
  background-image: url('/img/services-rentals.jpg') !important;
}
.hero-sales{
  background-image: url('/img/services-sales.jpg') !important;
}
.hero-special-deals{
  background-image: url('/img/services-special-deals.jpg') !important;
}
.hero-used-gear{
  background-image: url('/img/services-used-gear.jpg') !important;
}
.hero-used-gear{

}
.hero-special-deals{

}
#sticky{
  display:none;
}
.input, .textarea{
  border-radius:2px;
}
.autocomplete-wrapper input{
  @extend .input;
  margin-bottom:1em;
}
.menu-list ul{
  display:none;
}
.menu-list .is-active + ul, .menu-list .expanded + ul{
  display:block;
}
.menu-list li{
  position:relative;
}
.menu-list li a{
  padding-right:3em;
}
.toggleSubNav{
  background:white;
  position: absolute;
  right:11px;
  top:11px;
  width:25px;
  height:25px;
  padding:0 !important;
  &:after, &:before{
    content:'';
    position: absolute;
    background: $primary;
    top: 16px;
    right:12px;
    width:8px;
    height:2px;
    transform: rotate(45deg);
    transform-origin: right;
  }
  &:after{
    transform: rotate(135deg);
  }
}
.autocomplete-list ul{
  z-index: 9999;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.pageScroll{
  z-index:9999;
  width:25px;
  height:25px;
  position:absolute;
  bottom:4em;
  left:50%;
  margin-left:-12.5px;
  &:after, &:before{
    content:'';
    position: absolute;
    background: white;
    top: 16px;
    right:12px;
    width:12px;
    height:3px;
    transform: rotate(45deg);
    transform-origin: right;
  }
  &:after{
    transform: rotate(135deg);
  }
}
